export default {
  name: "LandingPage",
  created() {
    window.getRicToDetails = this.getRicToDetails;
    this.$nextTick(function() {
      /*显示table list*/
      this.showTable();
      /*获取ric list*/
      this.getRiclist();
      /*打印table*/
      this.printTable();
      //关联打印table
      this.correlationPrintTbale();
      //combobox
      for (var i = 1; i <= 6; i++) {
        this.$js.jqcombobox("#matrix-combobox" + i);
      }
    });
  },
  mounted() {
    this.$nextTick(function() {});
  },
  data() {
    return {
      dsply_nmll: [],
      mouse: false
    };
  },
  methods: {
    // 重整 bid & ask
    info: function() {
      let timer1;
      this.getRiclist();
      $("#info").attr("disabled", true);
      clearTimeout(timer1);
      //设置一次性定时器
      timer1 = setTimeout(function() {
        $("#info").attr("disabled", false);
      }, 5000);
      this.handleMouse(false);
    },
    //  Refresh 按钮提示
    handleMouse: function(flag) {
      this.mouse = flag;
    },
    showTable: function() {
      /*显示,关闭table list*/
      for (var i = 1; i <= 6; i++) {
        //利用闭包处理循环绑定 -- 解决变量污染问题
        (function(index) {
          $("#bn" + index + " span").addClass("glyphicon-triangle-bottom");
          $("#datalist" + index).addClass("d-none");
          $("#bn" + index).on("click", function() {
            $("#datalist" + index).toggleClass("d-none");
            $("#bn" + index + " span").toggleClass("glyphicon-triangle-top");
            $("#bn" + index + " span").toggleClass("glyphicon-triangle-bottom");
          });
        })(i);
      }
    },

    getRiclist: function() {
      /*获取ric list*/
      let _this = this;
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetRICList",
        data: { token: "webkey" },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            console.log(data);
            for (var i = 1; i <= 6; i++) {
              $("#matrix-combobox" + i)
                .find("option:eq(0)")
                .nextAll()
                .remove();
              $.each(data.ric, (index, comment) => {
                if (this.$js.isRHB(comment.issuer_name)) {
                  $("#matrix-combobox" + i).append(
                    "<option value=" +
                      comment["ric"] +
                      ">" +
                      comment["dsply_nmll"] +
                      " (" +
                      comment["ticker"] +
                      ")" +
                      "</option>"
                  );
                }
              });
              _this.ricChange(i);
              // console.log($(".ui-widget_" + i).find('input').val());
              _this.$js.clickEliminate(".ui-widget_" + i);
            }
            /*判断页面默认值*/
            if (localStorage.getItem("priceMatrix") !== null) {
              var newArr = new Array(6).fill("");
              var arr = localStorage.getItem("priceMatrix").split(",");
              arr.forEach(function(it, index) {
                if (it !== "") {
                  /*判断哪一个panel有存储*/
                  for (var i = 0; i < data.ric.length; i++) {
                    if (data.ric[i].ric.indexOf(it) >= 0) {
                      /*判断存储的ric是否存在*/
                      $("#matrix-combobox" + (index + 1)).val("111");
                      _this.dsply_nmll = [];
                      _this.getRicdatas(index + 1, it, true);
                      newArr[index] = it;
                    }
                  }
                }
              });
              localStorage.setItem("priceMatrix", newArr);
            }
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    },

    ricChange: function(i) {
      let _this = this;
      $("#matrix-combobox" + i).combobox({
        select: function(event, ui) {
          _this.getRicdatas(i, this.value, false); /*获取当前ric数据*/
          $("#matrix-combobox" + i)
            .parent()
            .find("input")
            .blur();
        }
      });
    },

    getRicdatas: function(i, ric, isInit) {
      /*获取当前ric数据*/
      let _this = this;
      /*同时打开panel*/
      $("#bn" + i + " span").removeClass("glyphicon-triangle-bottom");
      $("#bn" + i + " span").addClass("glyphicon-triangle-top");
      $("#datalist" + i).removeClass("d-none");
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetPriceMatrixData",
        data: { token: "webkey", ric: ric },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            var ric_data = data.ric_data;
            var live_matrix = data.livematrix;
            _this.dsply_nmll[i - 1] = ric_data["dsply_nmll"];
            $(".ui-widget_" + i)
              .find("input")
              .val(ric_data["dsply_nmll"] + " (" + ric_data["ticker"] + ")");
            if (Number(data.soldout) !== 0) {
              $("#datalist" + i)
                .find(".soldout")
                .css({
                  opacity: "1"
                });
            } else {
              $("#datalist" + i)
                .find(".soldout")
                .css({
                  opacity: "0"
                });
            }
            $(".tableHead" + i + " thead tr:eq(0) td:last").text(
              ric_data["dsply_nmll"]
            );
            $(".tableHead" + i + " thead tr:eq(1) td:last").text(
              ric_data["exercise_price"] !== "N/A"
                ? this.$js.curreryRetrun(ric_data.underlying_curr) +
                    " " +
                    this.$js.formatNumber(ric_data["exercise_price"], 4, 0)
                : "N/A"
            );
            $(".tableHead" + i + " thead tr:eq(2) td:last").text(
              _this.$js.formatNumber(ric_data["conv_ratio"], 4, 0)
            );
            $(".tableHead" + i + " thead tr:eq(3) td:last").text(
              ric_data["maturity"]
            );
            $(".tableHead" + i + " thead tr:eq(4) td:last").text(
              ric_data["effective_gearing"] !== "N/A"
                ? _this.$js.formatNumber(ric_data["effective_gearing"], 1, 0) +
                    " x"
                : "N/A"
            );
            $(".tableHead" + i + " thead tr:eq(5) td:last").text(
              ric_data["sensitivity"] !== "N/A"
                ? _this.$js.formatNumber(ric_data["sensitivity"], 1, 0) +
                    " ticks"
                : "N/A"
            );
            $(".tablelist" + i + " thead tr:first th:first").text(
              ric_data["underlying_ticker"]
            );
            $(".tablelist" + i + " thead tr:first th:last").text(
              ric_data["dsply_nmll"]
            );
            var html = "";
            if (_this.$js.isRHB(ric_data.issuer_name)) {
              let annotation = "annotation";
              var colorclass = "cc-wh";
              var pastvalue = 0;
              $.each(live_matrix, function(index, datas) {
                annotation =
                  ric_data.underlying_bid === Number(datas.underlying_bid)
                    ? "annotation"
                    : " ";
                if (index == 0) {
                  html +=
                    '<tr class="' +
                    colorclass +
                    '"><td class="' +
                    annotation +
                    '">' +
                    _this.$js.reservedNumber(
                      datas.underlying_bid,
                      ric_data["underlying_ric"]
                    ) +
                    "</td><td>" +
                    _this.$js.reservedNumber(
                      datas.underlying_ask,
                      ric_data["underlying_ric"]
                    ) +
                    "</td><td>" +
                    _this.$js.reservedNumber(datas.bid) +
                    "</td><td>" +
                    _this.$js.reservedNumber(datas.ask) +
                    "</td></tr>";
                  pastvalue = datas.bid;
                  colorclass = "cc-wh";
                } else if (datas.bid == pastvalue) {
                  html +=
                    '<tr class="' +
                    colorclass +
                    '"><td class="' +
                    annotation +
                    '">' +
                    _this.$js.reservedNumber(
                      datas.underlying_bid,
                      ric_data["underlying_ric"]
                    ) +
                    "</td><td>" +
                    _this.$js.reservedNumber(
                      datas.underlying_ask,
                      ric_data["underlying_ric"]
                    ) +
                    "</td><td>" +
                    _this.$js.reservedNumber(datas.bid) +
                    "</td><td>" +
                    _this.$js.reservedNumber(datas.ask) +
                    "</td></tr>";
                } else if (datas.bid != pastvalue) {
                  if (colorclass == "cc-wh") {
                    colorclass = "cc-bg";
                  } else {
                    colorclass = "cc-wh";
                  }

                  html +=
                    '<tr class="' +
                    colorclass +
                    '"><td class="' +
                    annotation +
                    '">' +
                    _this.$js.reservedNumber(
                      datas.underlying_bid,
                      ric_data["underlying_ric"]
                    ) +
                    "</td><td>" +
                    _this.$js.reservedNumber(
                      datas.underlying_ask,
                      ric_data["underlying_ric"]
                    ) +
                    "</td><td>" +
                    _this.$js.reservedNumber(datas.bid) +
                    "</td><td>" +
                    _this.$js.reservedNumber(datas.ask) +
                    "</td></tr>";
                  pastvalue = datas.bid;
                }
              });
              if (live_matrix.length > 0) {
                html +=
                  "<tr><td colspan='4'>Publish Time: " +
                  _this.$js.formatDate(live_matrix[0].publish_time) +
                  live_matrix[0].publish_time.substring(10, 19) +
                  " GMT +8" +
                  " </td></tr>";
              }
              ga(
                "send",
                "event",
                "livematrix",
                "search",
                ric_data["dsply_nmll"]
              );
            } else {
              html =
                "<tr><td colspan='4'>Warrant matrix is only available for warrants issued by RHB</td></tr>";
            }
            // $.each(live_matrix, function (index, datas) {
            //   html += '<tr><td>'+this.$js.formatNumber(datas.underlying_bid,3,0)+'</td><td>'+this.$js.formatNumber(datas.underlying_ask,3,0)+'</td><td>'+this.$js.formatNumber(datas.bid,3,0)+'</td><td>'+this.$js.formatNumber(datas.ask,3,0)+'</td></tr>';
            //   //html += '<tr><td>'+'N/A'+'</td><td>'+'N/A'+'</td><td>'+'N/A'+'</td><td>'+'N/A'+'</td></tr>';
            // });
            $(".tablelist" + i + " tbody").html(html);
          }
        },
        error: function(error) {
          console.log(error);
        }
      });

      if (isInit !== true) {
        var arr = [];
        if (localStorage.getItem("priceMatrix") !== null) {
          arr = localStorage.getItem("priceMatrix").split(",");
        }
        arr[i - 1] = ric;
        // localStorage.clear()
        localStorage.setItem("priceMatrix", arr);
      }
    },

    printTable: function() {
      /*下载table*/
      let _this = this;
      $("#tableToExcel").click(function() {
        if (localStorage.getItem("priceMatrix") !== null) {
          var arr = localStorage.getItem("priceMatrix").split(",");
          var divBoxArr = [];
          arr.forEach(function(it, index) {
            var i = index + 1;
            if (it !== "") {
              /*判断哪一个panel有存储*/
              divBoxArr.push({
                div: "#datalist" + i,
                name: it.split(".")[0]
              });
            }
          });
          _this.$js.MatrixExport_table(divBoxArr);
        }
      });
    },

    correlationPrintTbale: function() {
      /*打印table*/
      let _this = this;
      $("#tableToWord").click(function() {
        if (localStorage.getItem("priceMatrix") !== null) {
          var arr = localStorage.getItem("priceMatrix").split(",");
          var printData;
          arr.forEach(function(it, index) {
            var i = index + 1;
            if (it !== "") {
              /*判断哪一个panel有存储*/
              printData += $("#datalist" + i).html();
              // _this.$js.pringtingTable("#datalist" + i);
            }
          });
          _this.$js.MatrixPringtingTable(printData);
        }
      });
    },
    getRicToDetails: function(i) {
      /*跳转至details*/
      var ric = "";
      if (localStorage.getItem("priceMatrix") !== null) {
        var arr = localStorage.getItem("priceMatrix").split(",");
        arr.forEach(function(it, index) {
          if (i == index) {
            ric = it;
          }
        });
      }
      localStorage.setItem("detailsRic", ric);
      location.href = "warrantdetails?dsplay_nmll=" + this.dsply_nmll[i];
    }
  }
};
